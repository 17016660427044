<template>
  <div>
    <handle-procedure-categories />

    <validation-observer
      #default="{invalid}"
      ref="refFormObserver"
    >
      <b-modal
        id="modal-handle-procedure"
        v-model="modalHandleProcedureShow"
        centered
        size="lg"
        :title="modalTitle"
        @hidden="resetForm"
      >
        <b-form>
          <b-row>

            <!-- Field: Code -->
            <b-col
              cols="12"
              md="2"
            >
              <validation-provider
                #default="validationContext"
                name="Código"
                rules="required"
                immediate
              >
                <b-form-group
                  label="Cód."
                  label-for="code"
                >
                  <b-form-input
                    id="code"
                    v-model="procedure.code"
                    autofocus
                    :state="getValidationState(validationContext) ? null : false"
                    trim
                  />
                  <b-form-invalid-feedback>Obrigatório</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

            </b-col>

            <!-- Field: Procedure -->
            <b-col
              cols="12"
              md="7"
            >
              <validation-provider
                #default="validationContext"
                name="Procedimento"
                rules="required"
                immediate
              >
                <b-form-group
                  label="Procedimento"
                  label-for="procedure"
                >
                  <b-form-input
                    id="procedure"
                    v-model="procedure.procedure"
                    :state="getValidationState(validationContext) ? null : false"
                    trim
                  />
                  <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Price -->
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                label="Valor"
                label-for="price"
              >
                <b-input-group>
                  <b-input-group-prepend is-text>
                    <span>R$</span>
                  </b-input-group-prepend>
                  <currency-input
                    id="price"
                    v-model="procedure.price"
                  />
                </b-input-group>
              </b-form-group>

            </b-col>

            <!-- Field: Category -->
            <b-col
              cols="12"
              md="12"
            >
              <validation-provider
                #default="validationContext"
                name="Categoria"
                rules="required"
                immediate
              >
                <b-input-group>
                  <label>Categoria</label>
                  <v-select
                    v-model="procedure.procedure_category"
                    :reduce="option => option.data.procedure_category"
                    :searchable="true"
                    :clearable="false"
                    input-id="procedure-category"
                    :options="procedureCategoriesOption"
                    placeholder="Selecione"
                    style="width: 93%"
                  >
                    <template #no-options="{}">
                      Não encontrado.
                    </template>
                  </v-select>

                  <b-input-group-append>
                    <b-button
                      v-b-modal.modal-procedure-categories
                      size="sm"
                      variant="outline-primary"
                    >
                      <feather-icon icon="PlusIcon" />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
                <small
                  v-show="!getValidationState(validationContext)"
                  class="text-danger"
                >{{ validationContext.errors[0] }}</small>
              </validation-provider>

            </b-col>

          </b-row>

        </b-form>

        <template #modal-footer="{ cancel }">
          <b-button
            v-if="actionFlag === 'add'"
            variant="primary"
            :disabled="invalid"
            @click="add"
          >
            Adicionar
          </b-button>
          <b-button
            v-else
            variant="primary"
            :disabled="invalid"
            @click="update"
          >
            Alterar
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="cancel()"
          >
            Cancelar
          </b-button>
        </template>
      </b-modal>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BInputGroup, BButton, BInputGroupPrepend, BInputGroupAppend,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
  required,
} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import CurrencyInput from '@/views/components/currency/CurrencyInput.vue'
import HandleProcedureCategories from './procedureCategories/HandleProcedureCategories.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BInputGroup,
    BInputGroupPrepend,
    BButton,
    BInputGroupAppend,
    BFormInvalidFeedback,

    // Form Validation
    ValidationProvider,
    ValidationObserver,

    HandleProcedureCategories,
    CurrencyInput,
    vSelect,
  },
  props: {
    procedureCategoriesOption: {
      type: Array,
      default: () => [],
    },
  },

  setup(props, { emit }) {
    localize('pt_BR') // vee-validate messages
    const modalTitle = ref('Adicionar Procedimento na Tabela')
    const modalHandleProcedureShow = ref(false)
    const actionFlag = ref('add')
    const procedure = ref({
      idx: -1,
      id: null,
      code: null,
      procedure: null,
      price: 0,
      procedure_category: null,
    })

    const add = () => {
      modalHandleProcedureShow.value = false
      const resolvedProcedure = JSON.parse(JSON.stringify(procedure.value))
      resolvedProcedure.procedure_category = { procedure_category: resolvedProcedure.procedure_category }
      emit('add', resolvedProcedure)
    }

    const update = () => {
      modalHandleProcedureShow.value = false
      const resolvedProcedure = JSON.parse(JSON.stringify(procedure.value))
      resolvedProcedure.procedure_category = { procedure_category: resolvedProcedure.procedure_category }
      emit('update', resolvedProcedure)
    }

    const fillProcedure = data => {
      const dt = JSON.parse(JSON.stringify(data.item))
      dt.idx = data.index
      dt.procedure_category = dt.procedure_category.procedure_category
      procedure.value = dt
    }

    const resetFormData = () => {
      modalTitle.value = 'Adicionar Procedimento na Tabela'
      procedure.value = JSON.parse(JSON.stringify({
        idx: -1,
        id: null,
        code: null,
        procedure: null,
        price: 0,
        procedure_category: null,
      }))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetFormData)

    return {
      procedure,
      refFormObserver,
      required,
      modalHandleProcedureShow,
      modalTitle,
      actionFlag,

      getValidationState,
      fillProcedure,
      resetForm,
      add,
      update,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

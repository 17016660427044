import { ref, watch, computed } from '@vue/composition-api'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'

export default function useCategoryProcedures() {
  // Use toast
  const toast = useToast()

  const refCategoryProceduresListTable = ref(null)
  const procedureCategoryButtonText = ref('Adicionar')
  const modalSaveCategoryShow = ref(false)

  const procedureCategory = ref({
    id: null,
    code: null,
    procedure_category: null,
  })

  // Table Handlers
  const tableColumns = [
    {
      key: 'code',
      label: 'Cód.',
      sortable: true,
      tdClass: 'codeTdClass',
    },
    {
      key: 'procedure_category',
      label: 'Categoria',
      formatter: title,
      sortable: true,
    },
    {
      key: 'actions',
      label: 'ações',
      tdClass: 'actionTdClass',
      sortable: false,
    },
  ]
  const perPage = ref(5)
  const totalData = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [5, 10]
  const searchQuery = ref('')
  const sortBy = ref('procedure_category')
  const isSortDirDesc = ref(false)

  const dataMeta = computed(() => {
    const localItemsCount = refCategoryProceduresListTable.value ? refCategoryProceduresListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalData.value,
    }
  })

  const refetchData = () => {
    refCategoryProceduresListTable.value.refresh()
  }

  const showToast = (variant = 'success', icon = 'CheckIcon', titleToast = 'Sucesso', text = 'Dados Salvos com Sucesso.') => {
    toast({
      component: ToastificationContent,
      props: {
        title: titleToast,
        icon,
        text,
        variant,
      },
    })
  }

  const fetchProcedureCategories = (ctx, callback) => {
    store
      .dispatch('store-procedure-category/fetch', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sort: isSortDirDesc.value ? 'desc' : 'asc',
      })
      .then(response => {
        const { data, total } = response.data

        callback(data)
        totalData.value = total
      })
      .catch(e => {
        let message = 'Erro ao preencher lista de dados!'
        if (e.response.status === 401) {
          message = 'Sua sessão expirou, faça novo login!'
          showToast('danger', 'AlertTriangleIcon', message, null)
        } else if (e.response.status === 412) {
          message = e.response.data.message
          showToast('warning', 'AlertTriangleIcon', message, null)
        } else {
          showToast('danger', 'AlertTriangleIcon', message, null)
        }
      })
  }

  const save = async () => {
    try {
      if (procedureCategory.value.id) await store.dispatch('store-procedure-category/update', procedureCategory.value)
      else await store.dispatch('store-procedure-category/add', procedureCategory.value)
      refetchData()
      modalSaveCategoryShow.value = false
      showToast()
    } catch (e) {
      let message = 'Erro ao salvar dados!'
      if (e.response.status === 401) {
        message = 'Sua sessão expirou, faça novo login!'
        showToast('danger', 'AlertTriangleIcon', message, null)
      } else if (e.response.status === 412) {
        message = e.response.data.message
        showToast('warning', 'AlertTriangleIcon', message, null)
      } else {
        showToast('danger', 'AlertTriangleIcon', message, null)
      }
    }
  }

  const deleteData = async data => {
    try {
      await store.dispatch('store-procedure-category/delete', data)
      showToast('success', 'CheckIcon', 'Sucesso', 'Dados Excluídos com Sucesso.')
      refetchData()
    } catch (e) {
      let message = 'Erro ao excluir dados!'
      if (e.response.status === 401) message = 'Sua sessão expirou, faça novo login!'
      if (e.response.status === 412) message = e.response.data.message
      showToast('danger', 'AlertTriangleIcon', message, null)
    }
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  return {
    tableColumns,
    perPage,
    currentPage,
    totalData,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refCategoryProceduresListTable,
    procedureCategory,
    procedureCategoryButtonText,
    modalSaveCategoryShow,

    fetchProcedureCategories,
    save,
    deleteData,
    refetchData,
  }
}

<template>
  <b-modal
    id="modal-import-procedures"
    v-model="showLocal"
    centered
    size="lg"
    title="Importar Procedimentos"
    @hidden="hidden"
  >
    <b-alert
      variant="warning"
      show
    >
      <h4 class="alert-heading">
        Atenção!
      </h4>
      <div class="alert-body">

        <p>
          Ao selecionar a opção (Apagar procedimentos existentes), os procedimentos da tabela atual serão <strong>totalmente</strong> removidos.
          Caso não selecione esta opção, procedimentos com mesmo <strong>código e nome</strong> terão seus valores alterados,
          novos procedimentos serão incluídos e o restante permanecerá inalterado. <br>
          Caso a categoria não exista, uma nova categoria será criada.
        </p>
        <p>Somente arquivos do tipo <strong>Tabela EXCEL</strong> serão aceitos e devem ter o seguinte formato:</p>
        <hr>
        <table
          border="1"
          width="100%"
        >
          <thead>
            <th>codigo</th>
            <th>procedimento</th>
            <th>categoria</th>
            <th>valor</th>
          </thead>
          <tbody>
            <tr>
              <td>123-abc</td>
              <td>abc</td>
              <td>abc</td>
              <td>9999,99</td>
            </tr>
            <tr>
              <td>123-abc</td>
              <td>abc</td>
              <td>abc</td>
              <td>9999,99</td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-alert>

    <b-card class="mb-0">
      <b-form-checkbox
        v-model="selected"
        class="custom-control-danger"
      >
        <em v-if="!selected">Apagar TODOS os procedimentos existentes.</em>
        <em
          v-else
          class="text-danger"
        >
          Removerei TODOS os procedimentos da tabela. <br>
        </em>
      </b-form-checkbox>

      <!-- Accept specific image formats by extension -->
      <label
        for="extension"
        class="mt-1"
      >Somente extensões .css, .xlsx, .xls e .ods são aceitas.</label>
      <b-form-file
        id="extension"
        v-model="file"
        :state="Boolean(file)"
        browse-text="Buscar Arquivo"
        placeholder="Nenhum arquivo selecionado"
        accept=".css, .xlsx, .xls, .ods"
      />

    </b-card>

    <template #modal-footer="{ cancel }">
      <b-button
        variant="primary"
        :disabled="!Boolean(file)"
        @click="importProceduresClient"
      >
        Importar
      </b-button>
      <b-button
        variant="outline-secondary"
        @click="cancel()"
      >
        Cancelar
      </b-button>
    </template>

  </b-modal>
</template>

<script>
import axios from '@axios'
import { toRefs, ref, watch } from '@vue/composition-api'
import {
  BAlert, BCard, BFormCheckbox, BFormFile, BButton,
} from 'bootstrap-vue'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import XLSX from 'xlsx'

export default {
  components: {
    BAlert,
    BCard,
    BFormCheckbox,
    BFormFile,
    BButton,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    // Use toast
    const toast = useToast()

    const selected = ref(false)
    const file = ref(null)

    const showLocal = ref(false)

    // necessário para observar props
    const { show } = toRefs(props)
    watch(show, newValue => {
      showLocal.value = newValue
    })

    const hidden = () => {
      selected.value = false
      file.value = null
      emit('hidden')
    }

    const showToast = (variant = 'success', icon = 'CheckIcon', titleToast = 'Sucesso', text = 'Dados Salvos com Sucesso.') => {
      toast({
        component: ToastificationContent,
        props: {
          titleToast,
          icon,
          text,
          variant,
        },
      },
      {
        timeout: 8000,
      })
    }

    /**
     * NUM PRIMEIRO MOMENTO A IMPORTAÇÃO ERA FEITA SOMENTE DO LADO DO SERVIDOR
     * COM O ENVIO DO ARQUIVO.
     * EM UMA ATUALIZAÇÃO PASSAMOS ANÃO UTILZAR MAIS ESSE MODELO QUE FOI SUBSTITUIDO
     * POR UMA IMPORTAÇÃO DIRETA NO CLIENTE E POSTERIORMENTE SALVO (POR HORA MANTEREMOS ESSA FUNÇÃO MESMO SEM UTIILIZÁ-LA)
     */
    const importProcedures = async () => {
      try {
        const formData = new FormData()
        formData.append('delete_procedures', selected.value)
        formData.append('file', file.value)

        await axios.post(`${process.env.VUE_APP_API_URL}/procedures/import`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
        emit('hidden')
        emit('finish')
        showToast('success', 'ClockIcon', 'Sucesso', 'Dados enviados para a fila de serviços. A importação será finalizada em alguns instantes.')
      } catch (error) {
        if (error.response.status === 412) {
          showToast('warning', 'AlertCircleIcon', 'Atenção', error.response.data.message)
        } else {
          showToast('danger', 'AlertCircleIcon', 'Atenção', error.message)
        }
      }
    }

    const resolveExcelData = data => data.slice(1).map(e => {
      const [code, procedure, procedureCategory, price] = e
      return {
        code,
        procedure,
        procedure_category: { procedure_category: procedureCategory },
        price,
      }
    })

    const importProceduresClient = () => {
      if (file.value) {
        const reader = new FileReader()

        reader.onload = e => {
          /* Parse data */
          const bstr = e.target.result
          const wb = XLSX.read(bstr, { type: 'binary' })
          /* Get first worksheet */
          const wsname = wb.SheetNames[0]
          const ws = wb.Sheets[wsname]
          /* Convert array of arrays */
          const data = XLSX.utils.sheet_to_json(ws, { header: 1 })
          emit('hidden')
          emit('finish', resolveExcelData(data), selected.value)
        }

        reader.readAsBinaryString(file.value)
      }
    }

    return {
      showLocal,
      selected,
      file,
      hidden,
      importProcedures,
      importProceduresClient,
    }
  },
}
</script>

<style>

</style>

<template>

  <div>
    <handle-price-list
      ref="refHandlePriceList"
      @save="save"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="2"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Exibir</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="8"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block"
                placeholder="Buscar..."
                debounce="500"
              />

            </div>
          </b-col>

          <b-col
            cols="12"
            md="2"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                variant="primary"
                block
                @click="$bvModal.show('modal-handle-price-list')"
              >
                Nova Tabela
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refPriceListTable"
        class="position-relative tableMinHeght"
        :items="fetch"
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Nenhum resultado encontrado"
        :sort-desc.sync="isSortDirDesc"
        no-sort-reset
      >

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-media vertical-align="center">
            <b-badge
              pill
              :variant="`light-${resolvePriceTableListStatusVariant(data.item.status)}`"
              class="text-capitalize"
            >
              {{ data.item.status === 'ACTIVE' ? 'Ativo' : 'Inativo' }}
            </b-badge>
          </b-media>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="!$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="handleEdit(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item>

            <b-dropdown-item @click="handleDuplicate(data.item)">
              <feather-icon icon="CopyIcon" />
              <span class="align-middle ml-50">Duplicar</span>
            </b-dropdown-item>

            <b-dropdown-item @click="handleDelete(data.item)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Excluir</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Exibindo {{ dataMeta.from }} a {{ dataMeta.to }} de {{ dataMeta.of }} entradas</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalData"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BDropdown, BDropdownItem, BPagination, BButton, BMedia, BBadge,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref, onUnmounted } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
// eslint-disable-next-line import/no-cycle
import usePriceList from './usePriceList'
import priceListStoreModule from '../priceListStoreModule'
import HandlePriceList from './HandlePriceList.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BButton,
    BMedia,
    BBadge,

    HandlePriceList,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    vSelect,
  },
  setup(props, { root }) {
    const USER_APP_STORE_MODULE_NAME = 'store-price-list'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, priceListStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const batchAdjustmentPriceTableShowModal = ref(false)

    const {
      tableColumns,
      perPage,
      currentPage,
      totalData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      categoryFilter,
      refPriceListTable,

      refetchData,
      fetch,
      save,
      deleteData,
      duplicateData,
      resolvePriceTableListStatusVariant,
    } = usePriceList()

    const batchAdjustmentPriceTable = () => {
      batchAdjustmentPriceTableShowModal.value = true
    }

    const handleDuplicate = data => {
      root.$bvModal
        .msgBoxConfirm(`Deseja DUPLICAR a Tabela de Preços ${data.name}?`, {
          title: 'Por Favor Confirme esta Ação',
          size: 'sm',
          okVariant: 'warning',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) duplicateData(data)
        })
    }

    const handleDelete = data => {
      root.$bvModal
        .msgBoxConfirm(`Deseja Excluir a Tabela de Preços ${data.name}?`, {
          title: 'Por Favor Confirme esta Ação',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) deleteData(data)
        })
    }

    return {
      tableColumns,
      perPage,
      currentPage,
      totalData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      categoryFilter,
      refPriceListTable,
      batchAdjustmentPriceTableShowModal,

      fetch,
      handleDelete,
      handleDuplicate,
      refetchData,
      save,
      batchAdjustmentPriceTable,
      deleteData,
      duplicateData,
      resolvePriceTableListStatusVariant,

    }
  },

  methods: {
    handleEdit(data) {
      this.$refs.refHandlePriceList.fillPriceList(data)
      this.$bvModal.show('modal-handle-price-list')
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  .codeTdClass {
    width: 150px;
  }
  .statusTdClass {
    width: 120px;
  }
  .actionsTdClass {
    width: 120px;
  }

</style>

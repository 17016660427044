<template>
  <div>
    <!-- Import Procedures -->
    <import-procedures
      :show="importProceduresShowModal"
      @hidden="importProceduresShowModal = false"
      @finish="handleExcelData"
    />

    <handle-procedure
      ref="refHandleProcedure"
      :procedure-categories-option="procedureCategoriesOption"
      @add="addProcedure"
      @update="updateProcedure"
    />

    <b-modal
      id="modal-adjust-prices"
      v-model="modalAdjustPricesShow"
      centered
      size="sm"
      title="Definição de reajuste"
      @hidden="hidden"
    >
      <b-card
        class="mb-0"
      >
        <b-alert
          v-if="!selected.length"
          variant="warning"
          show
        >
          <h4 class="alert-heading">
            Atenção!
          </h4>
          <div class="alert-body">
            <p>Selecione ao menos um procedimento para reajuste de preço em lote.</p>
          </div>
        </b-alert>

        <div v-else>
          <em>{{ selected.length }} procedimentos selecionados.</em>
          <b-form-group
            :label="label"
            label-for="add-value"
            class="mt-1"
          >
            <b-input-group>
              <b-form-input
                id="add-value"
                v-model="value"
                :placeholder="placeholder"
                type="number"
                :style="!valueMode ? 'border-color: #ea5455': ''"
              />
              <b-input-group-append>
                <b-button
                  :variant="variant"
                  @click="changeValueMode"
                >
                  <feather-icon :icon="icon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>

      </b-card>

      <template #modal-footer="{ cancel }">
        <b-button
          variant="primary"
          :disabled="!Boolean(value > 0)"
          @click="adjustPrice"
        >
          Reajustar
        </b-button>
        <b-button
          variant="outline-secondary"
          @click="cancel()"
        >
          Cancelar
        </b-button>
      </template>

    </b-modal>

    <validation-observer
      #default="{invalid}"
      ref="refFormObserver"
    >
      <b-modal
        id="modal-handle-price-list"
        v-model="modalHandleProcedureShow"
        centered
        size="xl"
        title="Tabela de Preços"
        @hidden="resetForm(); selected = []"
      >

        <!-- Table Container Card -->
        <b-card
          no-body
          class="mb-0"
        >

          <b-form class="pl-2 pr-2 pt-2">
            <b-row>

              <!-- Field: Code -->
              <b-col
                cols="12"
                md="2"
              >
                <validation-provider
                  #default="validationContext"
                  name="Código"
                  rules="required"
                  immediate
                >
                  <b-form-group
                    label="Código"
                    label-for="code"
                  >
                    <b-form-input
                      id="code"
                      v-model="priceList.code"
                      autofocus
                      :state="getValidationState(validationContext) ? null : false"
                      trim
                    />
                    <b-form-invalid-feedback>Obrigatório</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

              </b-col>

              <!-- Field: Procedure -->
              <b-col
                cols="12"
                md="5"
              >
                <validation-provider
                  #default="validationContext"
                  name="Nome"
                  rules="required"
                  immediate
                >
                  <b-form-group
                    label="Nome"
                    label-for="name"
                  >
                    <b-form-input
                      id="name"
                      v-model="priceList.name"
                      :state="getValidationState(validationContext) ? null : false"
                      trim
                    />
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col
                cols="12"
                md="5"
              >
                <b-form-group
                  label="Planos"
                  label-for="dental-plans-id"
                >
                  <v-select
                    id="dental-plans-id"
                    v-model="priceList.dentalPlans"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :reduce="option => option.value"
                    :options="dentalPlanOptions"
                    placeholder="Selecione"
                    multiple
                  >
                    <template #no-options="{}">
                      Nenhum resultado encontrado.
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>

            </b-row>
          </b-form>

          <div class="m-2">

            <!-- Table Top -->
            <b-row>

              <!-- Search -->
              <b-col
                cols="12"
                md="8"
              >
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="searchQuery"
                    class="d-inline-block"
                    placeholder="Buscar..."
                    debounce="500"
                  />

                </div>
              </b-col>

              <b-col
                cols="12"
                md="4"
              >
                <b-dropdown
                  split
                  block
                  right
                  split-variant="primary"
                  variant="primary"
                  text="Novo Procedimento"
                  @click="$bvModal.show('modal-handle-procedure')"
                >
                  <b-dropdown-item
                    href="#"
                    @click="importProcedures"
                  >
                    <feather-icon
                      icon="UploadIcon"
                      size="16"
                    />
                    <span class="align-middle ml-50">Importar Procedimentos</span>
                  </b-dropdown-item>

                  <b-dropdown-item
                    href="#"
                    @click="$bvModal.show('modal-adjust-prices')"
                  >
                    <feather-icon
                      icon="DollarSignIcon"
                      size="16"
                    />
                    <span class="align-middle ml-50">Reajustar Valores</span>
                  </b-dropdown-item>
                </b-dropdown>

              </b-col>
            </b-row>

          </div>

          <b-table
            ref="refProcedureListTable"
            v-model="tableItems"
            class="position-relative tableMinHeght"
            :items="fetchProcedures"
            responsive
            sticky-header="500px"
            :fields="tableColumns"
            primary-key="id"
            sort-by="procedure"
            show-empty
            empty-text="Nenhum resultado encontrado"
            empty-filtered-text="Nenhum resultado encontrado para esta pesquisa"
            :sort-desc="false"
            no-sort-reset
            select-mode="multi"
            selectable
            :filter="searchQuery"
            @row-selected="onRowSelected"
            @head-clicked="selectAll"
          >

            <template #cell(price)="data">
              <div class="d-flex align-items-center justify-content-end">
                <b-input-group>
                  <b-input-group-prepend is-text>
                    <span>R$</span>
                  </b-input-group-prepend>
                  <currency-input
                    v-model="data.item.price"
                  />
                </b-input-group>
              </div>
            </template>

            <template #head(selectAll)>
              <template v-if="selected && selected.length === fetchProcedures.length">
                <feather-icon
                  icon="CheckSquareIcon"
                  class="text-primary"
                  size="15"
                />
              </template>
              <template v-else-if="selected && selected.length > 0 && selected.length < fetchProcedures.length">
                <feather-icon
                  icon="MinusSquareIcon"
                  class="text-primary"
                  size="15"
                />
              </template>
              <template v-else>
                <feather-icon
                  icon="SquareIcon"
                  class="text-primary"
                  size="15"
                />
              </template>
            </template>

            <template #cell(selectAll)="{ rowSelected }">
              <template v-if="rowSelected">
                <feather-icon
                  icon="CheckSquareIcon"
                  class="text-primary"
                  size="15"
                />
              </template>
              <template v-else>
                <feather-icon
                  icon="SquareIcon"
                  class="text-primary"
                  size="15"
                />
              </template>
            </template>

            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <feather-icon
                title="Editar"
                icon="EditIcon"
                @click.stop="handleEdit(data)"
              />

              <feather-icon
                title="Deletar"
                class="ml-2"
                icon="TrashIcon"
                @click.stop="handleDelete(data)"
              />

              <!-- <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >

                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>

                <b-dropdown-item @click="handleEdit(data)">
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">Editar</span>
                </b-dropdown-item>

                <b-dropdown-item @click="handleDelete(data)">
                  <feather-icon icon="TrashIcon" />
                  <span
                    class="align-middle ml-50"
                  >Excluir</span>
                </b-dropdown-item>
              </b-dropdown> -->
            </template>

          </b-table>

          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted">Total: {{ fetchProcedures.length }} registros.</span>
              </b-col>
            </b-row>
          </div>
        </b-card>
        <template #modal-footer="{ cancel }">
          <b-button
            variant="primary"
            :disabled="invalid"
            @click="$emit('save', { priceList, procedures: fetchProcedures }); modalHandleProcedureShow = false"
          >
            Salvar
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="cancel()"
          >
            Cancelar
          </b-button>
        </template>

      </b-modal>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormInput, BButton, BCard, BTable, BDropdown, BDropdownItem, BInputGroup,
  BInputGroupPrepend, BForm, BFormGroup, BFormInvalidFeedback, BAlert, BInputGroupAppend,
} from 'bootstrap-vue'
import {
  required,
} from '@validations'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { onUnmounted, ref, computed } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'

import vSelect from 'vue-select'
import Vue from 'vue'
import ImportProcedures from '@/views/components/import-procedures/ImportProcedures.vue'
import procedureCategoryStoreModule from '@/views/apps/procedures/procedureCategoryStoreModule'
import store from '@/store'
import CurrencyInput from '@/views/components/currency/CurrencyInput.vue'
import dentalPlanStoreModule from '@/views/apps/dental-plans/useStoreDentalPlans'
import HandleProcedure from './HandleProcedure.vue'
import procedureStoreModule from '../../procedures/procedureStoreModule'
import useProceduresPriceList from './useProceduresPriceList'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BButton,
    BCard,
    BTable,
    BDropdown,
    BDropdownItem,
    BInputGroup,
    BInputGroupPrepend,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BAlert,
    BInputGroupAppend,

    // Form Validation
    ValidationProvider,
    ValidationObserver,

    ImportProcedures,
    HandleProcedure,
    CurrencyInput,
    vSelect,
  },

  setup(props, { root }) {
    localize('pt_BR') // vee-validate messages

    const USER_APP_STORE_MODULE_NAME = 'store-procedure'
    const USER_APP_STORE_MODULE2_NAME = 'store-procedure-category'
    const USER_APP_STORE_MODULE3_NAME = 'store-dental-plan'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, procedureStoreModule)
    if (!store.hasModule(USER_APP_STORE_MODULE2_NAME)) store.registerModule(USER_APP_STORE_MODULE2_NAME, procedureCategoryStoreModule)
    if (!store.hasModule(USER_APP_STORE_MODULE3_NAME)) store.registerModule(USER_APP_STORE_MODULE3_NAME, dentalPlanStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
      if (store.hasModule(USER_APP_STORE_MODULE2_NAME)) store.unregisterModule(USER_APP_STORE_MODULE2_NAME)
      if (store.hasModule(USER_APP_STORE_MODULE3_NAME)) store.unregisterModule(USER_APP_STORE_MODULE3_NAME)
    })

    store.dispatch('store-dental-plan/fetch')
    const dentalPlanOptions = computed(() => store.getters['store-dental-plan/forSelect'])
    const procedureCategoriesOption = computed(() => store.getters['store-procedure-category/categoryProcedures'])

    const importProceduresShowModal = ref(false)
    const modalHandleProcedureShow = ref(false)
    const refHandleProcedure = ref(null)
    const searchQuery = ref('')

    const tableItems = ref([])

    const blankPriceList = {
      id: null,
      code: null,
      name: null,
      dentalPlans: [],
    }
    const priceList = ref(JSON.parse(JSON.stringify(blankPriceList)))
    const resetFormData = () => {
      priceList.value = JSON.parse(JSON.stringify(blankPriceList))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetFormData)

    const importProcedures = () => {
      importProceduresShowModal.value = true
    }

    const {
      tableColumns,
      sortBy,
      isSortDirDesc,
      refProcedureListTable,
      selected,

      selectAll,
      onRowSelected,
      refetchData,
      fetchProcedures,
      fetchProcedureCategories,
      adjustPrice,
      value,
      label,
      placeholder,
      variant,
      icon,
      valueMode,
      hidden,
      changeValueMode,
      modalAdjustPricesShow,
      deleteData,
    } = useProceduresPriceList(priceList)

    const fillPriceList = data => {
      priceList.value = {
        id: data.id,
        code: data.code,
        name: data.name,
        dentalPlans: data.dental_plans.map(dp => dp.id),
      }
      fetchProcedures.value = data.procedures.map(e => {
        const procedureCategory = procedureCategoriesOption.value.find(pC => pC.data.id === e.procedure_category_id)
        e.procedure_category = { procedure_category: procedureCategory.label }
        return e
      })
    }

    const handleExcelData = (data, eraseAll) => {
      fetchProcedures.value = eraseAll ? data : fetchProcedures.value.concat(data)
    }

    const addProcedure = data => {
      fetchProcedures.value.push(data)
    }

    const updateProcedure = data => {
      if (data.idx >= 0) Vue.set(fetchProcedures.value, data.idx, data)
    }

    const handleEdit = data => {
      refHandleProcedure.value.modalTitle = 'Alterar Procedimento na Tabela'
      refHandleProcedure.value.actionFlag = 'update'
      refHandleProcedure.value.fillProcedure(JSON.parse(JSON.stringify(Object.assign(data))))
      root.$bvModal.show('modal-handle-procedure')
    }

    const handleDelete = data => {
      root.$bvModal
        .msgBoxConfirm(`Deseja Excluir o Procedimento ${data.item.procedure}?`, {
          title: 'Por Favor Confirme esta Ação',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(resp => {
          if (resp) deleteData(data)
        })
    }

    return {
      required,
      modalHandleProcedureShow,
      modalAdjustPricesShow,

      tableColumns,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refProcedureListTable,
      selected,
      priceList,
      hidden,
      changeValueMode,
      tableItems,

      fetchProcedures,
      fetchProcedureCategories,
      refetchData,
      getValidationState,
      resetForm,
      refFormObserver,
      onRowSelected,
      selectAll,
      value,
      label,
      placeholder,
      variant,
      icon,
      valueMode,
      adjustPrice,
      procedureCategoriesOption,
      dentalPlanOptions,
      fillPriceList,
      importProceduresShowModal,
      importProcedures,
      handleEdit,
      handleExcelData,
      addProcedure,
      updateProcedure,
      handleDelete,
      refHandleProcedure,
    }
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  .selectAllTdClass {
    width: 50px;
  }
  .codeTdClass {
    width: 206px;
  }
  .unitTdClass {
    width: 140px;
  }
  .priceTdClass {
    width: 206px;
  }
</style>

import {
  ref, onMounted,
} from '@vue/composition-api'
import { title, currency } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'

export default function useProceduresList() {
  // Use toast
  const toast = useToast()

  const refProcedureListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'selectAll',
      label: '#',
      sortable: false,
      tdClass: 'selectAllTdClass',
    },
    {
      key: 'code',
      label: 'Cód.',
      sortable: true,
      tdClass: 'priceTdClass',
    },
    {
      key: 'procedure_category.procedure_category',
      label: 'Categoria',
      formatter: title,
      sortable: true,
    },
    { key: 'procedure', label: 'Procedimento', sortable: true },
    {
      key: 'price',
      label: 'Preço',
      formatter: currency,
      sortable: true,
      tdClass: 'priceTdClass',
    },
    {
      key: 'actions',
      label: 'ações',
      tdClass: 'actionsTdClass',
      sortable: false,
    },
  ]

  const selected = ref([])
  const selectAll = item => {
    if (item === 'selectAll' && selected.value.length === 0) refProcedureListTable.value.selectAllRows()
    else if (selected.value.length > 0) refProcedureListTable.value.clearSelected()
  }

  const onRowSelected = items => {
    selected.value = items
  }

  const modalAdjustPricesShow = ref(false)

  const value = ref(0)
  const label = ref('Acréscimo %')
  const placeholder = ref('Valor % do acréscimo')
  const variant = ref('primary')
  const icon = ref('ArrowUpRightIcon')
  const valueMode = ref(true)

  const hidden = () => {
    value.value = 0
    label.value = 'Acréscimo %'
    placeholder.value = 'Valor % do acréscimo'
    variant.value = 'primary'
    icon.value = 'ArrowUpRightIcon'
    valueMode.value = true
  }

  const changeValueMode = () => {
    valueMode.value = !valueMode.value

    label.value = valueMode.value ? 'Acréscimo %' : 'Desconto %'
    placeholder.value = valueMode.value ? 'Valor % do acréscimo' : 'Valor % do desconto'
    variant.value = valueMode.value ? 'primary' : 'danger'
    icon.value = valueMode.value ? 'ArrowUpRightIcon' : 'ArrowDownRightIcon'
  }

  const adjustPrice = () => {
    selected.value = selected.value.map(procedure => {
      const formula = (procedure.price * (value.value / 100).toFixed(2))
      // eslint-disable-next-line no-param-reassign
      if (!valueMode.value) procedure.price -= formula
      // eslint-disable-next-line no-param-reassign
      else procedure.price += formula
      return procedure
    })
    modalAdjustPricesShow.value = false
  }

  const refetchData = () => {
    refProcedureListTable.value.refresh()
  }

  const showToast = (variantToast = 'success', iconToast = 'CheckIcon', titleToast = 'Sucesso', textToast = 'Dados Salvos com Sucesso.') => {
    toast({
      component: ToastificationContent,
      props: {
        title: titleToast,
        icon: iconToast,
        text: textToast,
        variant: variantToast,
      },
    })
  }

  const fetchProcedures = ref([])

  const fetchProcedureCategories = () => {
    store
      .dispatch('store-procedure-category/fetch')
      .then(response => response)
      .catch(e => {
        let message = 'Erro ao consultar categorias de procedimentos!'
        if (e.response.status === 401) message = 'Sua sessão expirou, faça novo login!'
        showToast('danger', 'AlertTriangleIcon', message, null)
      })
  }

  const deleteData = async data => {
    try {
      fetchProcedures.value.splice(data.index, 1)
      showToast('success', 'CheckIcon', 'Sucesso', 'Dados Excluídos com Sucesso.')
      refetchData()
    } catch (e) {
      showToast('danger', 'AlertCircleIcon', 'Atenção', 'Desculpe... Algo não saiu como esperado.')
    }
  }

  onMounted(() => {
    fetchProcedureCategories()
  })

  return {
    fetchProcedures,
    fetchProcedureCategories,
    tableColumns,
    refProcedureListTable,
    selected,
    value,
    label,
    placeholder,
    variant,
    icon,
    valueMode,
    modalAdjustPricesShow,

    onRowSelected,
    refetchData,
    selectAll,
    adjustPrice,
    hidden,
    changeValueMode,
    deleteData,
  }
}

<template>
  <div>
    <validation-observer
      #default="{invalid}"
      ref="refFormObserver"
    >
      <b-modal
        id="modal-save-category"
        v-model="modalSaveCategoryShow"
        centered
        size="lg"
        title="Categorias de Procedimentos"
        @hidden="resetForm"
      >
        <b-form>
          <b-row>

            <!-- Field: Code -->
            <b-col
              cols="12"
              md="3"
            >
              <validation-provider
                #default="validationContext"
                name="Código"
                rules="required"
                immediate
              >
                <b-form-group
                  label="Cód."
                  label-for="code"
                >
                  <b-form-input
                    id="code"
                    v-model="procedureCategory.code"
                    autofocus
                    :state="getValidationState(validationContext) ? null : false"
                    trim
                  />
                  <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

            </b-col>

            <!-- Field: Procedure Category -->
            <b-col
              cols="12"
              md="9"
            >

              <validation-provider
                #default="validationContext"
                name="Categoria"
                rules="required"
                immediate
              >
                <b-form-group
                  label="Categoria"
                  label-for="procedure-category"
                >

                  <b-form-input
                    id="procedure-category"
                    v-model="procedureCategory.procedure_category"
                    :state="getValidationState(validationContext) ? null : false"
                    trim
                  />
                  <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

            </b-col>

          </b-row>

        </b-form>
        <template #modal-footer="{ cancel }">
          <b-button
            variant="outline-secondary"
            @click="cancel()"
          >
            Cancelar
          </b-button>
          <b-button
            variant="primary"
            :disabled="invalid"
            @click="save"
          >
            <feather-icon :icon="!procedureCategory ? 'PlusIcon' : 'EditIcon'" />
            {{ procedureCategoryButtonText }}
          </b-button>
        </template>

      </b-modal>
    </validation-observer>

    <b-modal
      id="modal-procedure-categories"
      centered
      size="xl"
      title="Categorias de Procedimentos"
    >
      <b-card
        no-body
        class="mb-1 p-1"
      >

        <!-- Table Top -->
        <b-row class="mb-2">

          <!-- Per Page -->
          <b-col
            cols="12"
            md="2"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrar</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              style="width: 100%"
            />
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="8"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block"
                placeholder="Buscar..."
                debounce="500"
              />
            </div>
          </b-col>

          <!-- Add -->
          <b-col
            cols="12"
            md="2"
          >

            <b-button
              v-b-modal.modal-save-category
              block
              variant="primary"
            >
              <feather-icon icon="PlusIcon" />
              Categoria
            </b-button>
          </b-col>

        </b-row>

        <b-table
          ref="refCategoryProceduresListTable"
          class="position-relative tableMinHeght"
          :items="fetchProcedureCategories"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="Nenhum resultado encontrado"
          :sort-desc.sync="isSortDirDesc"
          no-sort-reset
        >

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>

              <b-dropdown-item @click="handleEdit(data.item)">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Editar</span>
              </b-dropdown-item>

              <b-dropdown-item @click="handleDelete(data.item)">
                <feather-icon icon="TrashIcon" />
                <span
                  class="align-middle ml-50"
                >Excluir</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Exibindo {{ dataMeta.from }} a {{ dataMeta.to }} de {{ dataMeta.of }} entradas</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="totalData"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>

      </b-card>

      <template #modal-footer="{ cancel }">
        <b-button
          variant="outline-secondary"
          @click="cancel()"
        >
          Cancelar
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BTable, BFormInvalidFeedback,
  BDropdown, BDropdownItem, BCard, BPagination,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
  required,
} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import vSelect from 'vue-select'
import useCategoryProcedures from './useProcedureCategories'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BTable,
    BFormInvalidFeedback,
    BDropdown,
    BDropdownItem,
    BCard,
    BPagination,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },

  setup(props, { root }) {
    localize('pt_BR') // vee-validate messages
    const {
      tableColumns,
      perPage,
      currentPage,
      totalData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCategoryProceduresListTable,
      procedureCategory,
      procedureCategoryButtonText,
      modalSaveCategoryShow,

      fetchProcedureCategories,
      refetchData,
      deleteData,
      save,
    } = useCategoryProcedures()

    const resetFormData = () => {
      procedureCategory.value = JSON.parse(JSON.stringify({
        id: null,
        code: null,
        procedure_category: null,
      }))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetFormData)

    const handleEdit = data => {
      procedureCategory.value = JSON.parse(JSON.stringify(data))
      procedureCategoryButtonText.value = 'Alterar'
      modalSaveCategoryShow.value = true
    }

    const handleDelete = data => {
      root.$bvModal
        .msgBoxConfirm(`Deseja Excluir a Categoria ${data.procedure_category}?`, {
          title: 'Por Favor Confirme esta Ação',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) deleteData(data)
        })
    }

    return {
      tableColumns,
      perPage,
      currentPage,
      totalData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCategoryProceduresListTable,
      procedureCategory,
      procedureCategoryButtonText,
      modalSaveCategoryShow,
      required,
      refFormObserver,

      getValidationState,
      resetForm,
      fetchProcedureCategories,
      refetchData,
      handleEdit,
      handleDelete,
      deleteData,
      save,
    }
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  .actionTdClass {
    width: 150px;
  }
  .codeTdClass {
    width: 150px;
  }

</style>
